
// IonInput
import {
  IonPage,
  IonContent,
  toastController,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  loadingController,
  alertController,
  actionSheetController,
  IonImg,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import Empty from "../../../components/Empty.vue";
import { Share } from "@capacitor/share";
import { useStore } from "vuex";
import { Analytics } from "../../../common/analytics";
/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    Empty,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonImg,
  },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const screenName = `PAGE-STAMP_${props.id}`;
    const analytics = new Analytics();
    const state = reactive({
      stampFillters: [],
      randomNum: Math.floor(Math.random() * 5),
      stamp: {
        loading: true,
        data: {
          id: 0,
          name: store.getters["getPage"].title,
          content: {
            shortDescription: "",
          },
          address: {},
          isUserReview: 0,
        },
      },
      mission: {
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
      },
      comment: {
        page: 1,
        size: 20,
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
      },
      review: {
        page: 1,
        size: 5,
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        average: 0,
        rating: 0,
      },
      user: {
        loading: true,
        data: {
          id: 0,
        },
      },
      contentLoading: true,
      isScrollDisabled: false,
      form: {
        content: "",
      },
    });

    const fetch = async (event: any = null, loadMore = false) => {
      await services.authUser().then(
        (response) => {
          const { data } = response;
          state.user.data = data;
          state.user.loading = false;
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
        }
      );
      if (!loadMore) {
        await services.stampFindone(props.id).then(
          (response) => {
            const { data } = response;
            if (event) {
              if (event.type == "ion-refresh") {
                state.stamp.data.id = 0;
              }
            }
            if (data.acquisition == 0) {
              state.stampFillters = [
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #d4ff6e)",
                },
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #7ae4ff)",
                },
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ffdf61)",
                },
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ebb5ff)",
                },
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ffc48d)",
                },
              ] as any;
            } else {
              state.stampFillters = [];
            }
            state.review.rating = Number((data.reviewScore / 20).toFixed(1));
            state.review.average = Math.round(state.review.rating) * 20;
            state.stamp.data = data;
            state.stamp.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.stamp.data.id = 0;
              }
            }
            state.stamp.loading = false;
          }
        );

        await services.relationFindone(props.id).then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.comment.items = [];
              }
            }

            state.mission.items = data.items;
            state.mission.totalItems = data.totalItems;
            state.mission.totalPages = data.totalPages;
            state.mission.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.mission.items = [];
              }
            }
            state.mission.loading = false;
          }
        );
      }

      await services
        .stampReviewFindAll({
          size: state.review.size,
          page: state.review.page,
          stampId: props.id,
        })
        .then(
          (response) => {
            const { data } = response;
            if (event) {
              if (event.type == "ion-refresh") {
                state.review.items = [];
              }
            }

            data.items.some((item: object) => {
              state.review.items.push(item);
            });

            state.review.totalItems = data.totalItems;
            state.review.totalPages = data.totalPages;
            state.review.loading = false;
            state.review.page++;
            if (event) {
              if (data.items?.length == 0 && loadMore) {
                state.isScrollDisabled = true;
              }
            }
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.review.items = [];
              }
            }
            state.review.loading = false;
            state.isScrollDisabled = true;
          }
        );

      // const params = {
      //   size: state.comment.size,
      //   page: state.comment.page,
      // };

      // await services.commentFindall(params).then(
      //   (response) => {
      //     const { data } = response;

      //     if (event) {
      //       if (event.type == "ion-refresh") {
      //         state.comment.items = [];
      //       }
      //     }

      //     data.items.some((item: object) => {
      //       state.comment.items.push(item);
      //     });

      //     state.comment.totalItems = data.totalItems;
      //     state.comment.totalPages = data.totalPages;
      //     state.comment.loading = false;
      //     state.comment.page++;
      //     if (event) {
      //       if (data.items?.length == 0 && loadMore) {
      //         state.isScrollDisabled = true;
      //       }
      //     }
      //   },
      //   (error) => {
      //     let message = "";
      //     try {
      //       message = error.response.data.message;
      //     } catch (e) {
      //       message = "";
      //       console.log(e);
      //     }
      //     console.log(message);
      //     console.log(message);
      //     if (event) {
      //       if (event.type == "ion-refresh") {
      //         state.comment.items = [];
      //       }
      //     }
      //     state.comment.loading = false;
      //     state.isScrollDisabled = true;
      //   }
      // );

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };
    // 댓글
    /*
    const onSubmit = async() => {
      const params = {
        content : state.form.content,
        postId : props.id
      };
      services.commentAdd(params)
      .then(
         response => {
             console.log(response);
          },
           error => {
            console.log(error);
          }
      )
    };
    */

    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const onRemoveReview = async (index: number, id: number) => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "삭제 중입니다.",
      });
      await loading.present();
      services
        .stampReviewRemove(id)
        .then((response) => {
          console.log(response);
          if (response.status == 204) {
            // 리뷰를 작제 했으니 다시 작성할수 있도록
            state.stamp.data.isUserReview = 0;

            setTimeout(function () {
              loading.dismiss();
            }, 1000);
            state.review.items.splice(index, 1);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        })
        .catch((error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        });
    };

    const reportLink = (tpye: string, id: number) => {
      store.commit("report", {
        tpye: tpye,
        id: id,
      });
      router.push({
        name: "SettingReport",
        params: { id: id },
      });
    };

    const presentConfirm = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
          {
            text: "취소",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    async function onReport(index: number, item: any, type: string) {
      const actionSheetButton = [];
      if (type == "review") {
        if (item.user.id == state.user.data.id) {
          console.log(item.id);
          actionSheetButton.push({
            text: "삭제하기",
            role: "destructive",
            handler: () => {
              onRemoveReview(index, item.id);
            },
          });
        } else {
          actionSheetButton.push({
            text: "리뷰 신고하기",
            handler: () => {
              reportLink("review", item.id);
            },
          });
        }
      }

      actionSheetButton.push({ text: "취소", role: "cancel" });

      const actionSheet = await actionSheetController.create({
        buttons: actionSheetButton,
      });
      await actionSheet.present();
    }

    const onReviewLink = () => {
      router.push({
        name: "StampReviewId",
        params: { id: state.stamp.data.id },
      });
    };

    const onMissionLink = (id: number) => {
      router.push({
        name: "MissionId",
        params: { id: id },
      });
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.review.page = 1;
      state.isScrollDisabled = false;
      state.stamp.loading = false;
      state.mission.loading = false;
      state.review.loading = false;

      fetch(event);
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    // watch
    watch(
      () => route.path,
      () => {
        const review = store.getters["getReview"];
        if (review.write) {
          // 리뷰 갱신
          store.commit("review", {
            write: false,
          });

          state.stamp.data.isUserReview = 1;

          state.review.page = 1;
          state.review.items = [];
          fetch(null, true);
        }
      }
    );

    // onMounted
    onMounted(() => {
      state.mission.items = [];
      state.comment.items = [];
      state.review.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    const onUserLink = (id: number) => {
      /*
      router.push({
        name: "UserId",
        params: {id: id}
      })
      */
    };

    const onClose = () => {
      window.history?.length > 1 ? router.go(-1) : router.push("/");
    };

    const onMapLink = (id: number, title: string) => {
      store.commit("page", {
        title: title,
      });
      router.push({
        name: "StampIdMap",
        params: { id: id },
      });
    };

    const openToast = async (message: string) => {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    };

    const onShare = async () => {
      if (navigator.share !== undefined) {
        await Share.share({
          title: state.stamp.data.name,
          text: state.stamp.data.content.shortDescription,
          url: `https://stamppop.com/stamp/${state.stamp.data.id}`,
          dialogTitle: `${state.stamp.data.name} 스탬프 공유`,
        });
      } else {
        const copyElement = document.createElement("textarea");
        copyElement.value = `https://stamppop.com/stamp/${state.stamp.data.id}`;
        document.body.appendChild(copyElement);

        copyElement.select();
        document.execCommand("copy");
        document.body.removeChild(copyElement);

        openToast("공유할 링크가 복사되었습니다.");
      }
    };

    return {
      state,
      onMissionLink,
      onMapLink,
      onUserLink,
      onClose,
      onShare,
      onRefresh,
      onLoadData,
      onReviewLink,
      onReport,
    };
  },
});
